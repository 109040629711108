import { TableGameWinCellProps } from './props';
import { BetOutcome } from '@/models/bet/outcome';
import styles from './styles.module.scss';
import { Image as CoreImage } from '@starsoft/common/components';
import { memo, useMemo } from 'react';
import { isPositive } from '@/utils/math/isPositive';
import { isNegative } from '@/utils/math/isNegative';
import { MoneyService as Money } from '@starsoft/common/services';

function TableGameBetCell({
  bet,
  showAmount,
  isProfitStyle,
  isFlexEnd,
}: TableGameWinCellProps) {
  function getPayout(): Money {
    const payout: Money = new Money({
      amount: 0,
      coin: bet?.coin,
    });

    if (showAmount) {
      payout.add(bet?.amount);
      return payout;
    }

    if (bet?.outcome === BetOutcome.Win) {
      payout.add(bet?.profit);
      payout.add(bet?.amount);
    } else {
      payout.add(bet?.profit);
    }

    return payout;
  }

  const payout: Money = useMemo(getPayout, [bet, showAmount]);

  return (
    <div
      className={`${styles.container} ${isProfitStyle && isPositive(payout.amount) ? styles['container--positive'] : ''} ${isProfitStyle && isNegative(payout.amount) ? styles['container--negative'] : ''} ${isFlexEnd ? styles['container--end'] : ''}`}
    >
      <CoreImage
        className={styles.container__coin}
        width={14}
        height={14}
        src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/coins/rounded/${
          bet?.coin?.image ?? 'brl.svg'
        }`}
      />
      {payout.formattedAmount}
    </div>
  );
}

export default memo(TableGameBetCell);
