import { CarouselsBiggestWinsFilterProps } from './props';
import { TabsWithVersion } from '@/components/core/TabsWithVersion';

export default function CarouselsBiggestWinFilter({
  currentOption,
  onOptionClick,
  options,
}: CarouselsBiggestWinsFilterProps) {
  return (
    <TabsWithVersion
      isTiny
      currentTab={currentOption}
      tabs={options}
      setTab={onOptionClick}
    />
  );
}
