import { useMemo } from 'react';
import { SectionProps } from './props';
import styles from './styles.module.scss';
import { ErrorCard } from '../ErrorCard';
import { PaginationWithProgress } from '../PaginationWithProgress';
import { NoDataComponent } from '../NoDataComponent';

export function Section<T>({
  paginated,
  paginationType,
  error,
  hasMore,
  isLoading,
  isLoadingMore,
  limit,
  loadMore,
  refetch,
  componenents: { component, skeleton },
  data,
  totalCount,
  className,
}: SectionProps<T>) {
  const Skeletons: JSX.Element[] = useMemo(
    () => Array.from({ length: limit }).map((_, index) => skeleton(index)),
    [limit, skeleton],
  );

  const Components: JSX.Element[] = useMemo(
    () => data?.map((data, index) => component(data, index)),
    [data, component],
  );

  if (error && refetch) {
    return (
      <div className={`${styles.container} ${styles['container--error']}}`}>
        <ErrorCard error={error} refetch={refetch} />
      </div>
    );
  }

  if (!isLoading && data?.length === 0) {
    return <NoDataComponent isSecondaryStyles isSmall />;
  }

  return (
    <div className={styles.container}>
      <div
        className={`${styles.container__content} ${className ? className : ''}`}
      >
        {Components}
        {((paginated && isLoadingMore) || isLoading) && Skeletons}
      </div>

      {!isLoading && paginationType == 'manual' && loadMore && (
        <PaginationWithProgress
          count={(data?.length as number) ?? 0}
          loading={isLoading as boolean}
          fetchNextPage={loadMore}
          isFetchingNextPage={isLoadingMore as boolean}
          hasNextPage={hasMore as boolean}
          totalCount={totalCount as number}
        />
      )}
    </div>
  );
}
