import { BiggestWinFilterOptions } from './props';

export function getBiggestWinsByEndDate(option: BiggestWinFilterOptions) {
  const endDate = new Date();

  let startDate: Date;

  switch (option) {
    case BiggestWinFilterOptions['1Day']:
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 1);
      break;
    case BiggestWinFilterOptions['3Days']:
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 3);
      break;
    case BiggestWinFilterOptions['7Days']:
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 7);
      break;
    default:
      throw new Error('Invalid filter option');
  }

  return {
    startDate,
    endDate,
  };
}
