import { useUnifiedBets } from '@/api/unified-bets/queries/useUnifiedBets';
import Carousel from '@/components/Home/Carousel';
import { ErrorCard } from '@starsoft/common/components';
import GameCardBiggestWins from '@/components/core/Games/GameCard/Versions/BiggestWins';
import { memo, useMemo, useState } from 'react';
import { BigestWinsCarouselProps } from './props';
import { getComputedColor } from '@/utils/theme';
import CarouselsBiggestWinFilter from './Filter';
import { biggesWinsFilterOptions } from './Filter/options';
import { BiggestWinFilterOptions } from './Filter/props';
import { getBiggestWinsByEndDate } from './Filter/helper';
import CarousselBiggestWinsIcon from './Icon';

function BigestWinsCarousel({ isSkeleton }: BigestWinsCarouselProps) {
  const [tab, setTab] = useState<BiggestWinFilterOptions>(
    BiggestWinFilterOptions['1Day'],
  );
  const { endDate, startDate } = useMemo(
    () => getBiggestWinsByEndDate(tab),
    [tab],
  );
  const { bets, isLoading, refetch, error } = useUnifiedBets({
    limit: 20,
    sortBy: 'multiplier',
    startDate,
    endDate,
  });

  const Bets = useMemo(
    () =>
      bets?.data?.map(bet => (
        <GameCardBiggestWins
          key={`home-biggest-wins-card-${bet?.id}`}
          bet={bet}
        />
      )),
    [bets],
  );

  const Skeletons = useMemo(
    () =>
      Array.from({ length: 20 }).map((_, idx) => (
        <GameCardBiggestWins
          key={`home-biggest-wins-card-${idx}-skeleton`}
          isLoading
        />
      )),
    [],
  );

  if (error) {
    return <ErrorCard error={error} refetch={refetch} />;
  }

  return (
    <Carousel
      size={4}
      filterComponent={
        <CarouselsBiggestWinFilter
          onOptionClick={_tab => setTab(_tab)}
          currentOption={tab}
          options={biggesWinsFilterOptions}
        />
      }
      label="common:biggest_wins_title"
      useTransComponent
      useAutoScroll
      color={getComputedColor('--primary-color')}
      customIcon={<CarousselBiggestWinsIcon />}
    >
      {isLoading || isSkeleton ? Skeletons : Bets}
    </Carousel>
  );
}

export default memo(BigestWinsCarousel);
