import { useEffect, useMemo } from 'react';
import { StoriesProps } from './props';
import styles from './styles.module.scss';
import StoryContent from './Content';
import StoriesHeader from './Header';
import { useModal } from '@/hooks/modals/useModal';
import { ModalsKey } from '@/enums/modalsKey';
import { useStories } from '@/hooks/useStories';
import StoryButton from './Button';

export default function Stories({ stories }: StoriesProps) {
  const { onClose } = useModal(ModalsKey.Story);
  const {
    current,
    countdown,
    handleTouchEnd,
    handleTouchMove,
    handleNextStory,
    handleTouchStart,
    handlePreviousStory,
  } = useStories({ stories });

  const Stories = useMemo(
    () =>
      stories.map((story, index) => (
        <StoryContent
          isCurrent={index == current}
          story={story}
          key={`story-image-${story.url}`}
        />
      )),
    [stories, current],
  );

  function handleCloseStories() {
    if (current == stories.length - 1) {
      setTimeout(() => {
        onClose();
      }, 4600);
    }
  }

  useEffect(handleCloseStories, [current, onClose, stories.length]);

  return (
    <div
      className={styles.container}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <StoriesHeader
        count={stories.length}
        countdown={Number(countdown)}
        current={current}
      />
      <div
        style={{
          transform: `translateX(calc(${current} * -100dvw))`,
        }}
        className={styles.container__content}
      >
        {Stories}
      </div>

      <StoryButton action="back" onClick={handlePreviousStory} />
      <StoryButton action="forward" onClick={handleNextStory} />
    </div>
  );
}
