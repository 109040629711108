import { Input } from '@starsoft/common/components';
import { RootState } from '@/lib/store';
import { setModalSearch, toggleSearch } from '@/lib/store/casino/actions';
import useTranslation from 'next-translate/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { GameSearchInputProps } from './props';
import { useEffect } from 'react';
import { useIsMobile } from '@starsoft/common/hooks';

export default function GameSearchInput({
  isLoading,
  focusOnMount,
}: GameSearchInputProps) {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const open: boolean = useSelector(
    (state: RootState) => state.casino.openSearch,
  );
  const search: string = useSelector(
    (state: RootState) => state.casino.modalSearch,
  );
  const isMobile = useIsMobile();

  function handleOnClick() {
    if (open || focusOnMount) {
      return;
    }

    dispatch(toggleSearch());
  }

  function resetSearchValue(): void {
    dispatch(setModalSearch(''));

    if (!open) {
      return;
    }

    if (!search || isMobile) {
      dispatch(toggleSearch());
    }
  }

  function handleSearchValue(e: React.ChangeEvent<HTMLInputElement>): void {
    dispatch(setModalSearch(e.target.value));
  }

  function onUnmount() {
    return () => {
      dispatch(setModalSearch(''));
    };
  }

  useEffect(onUnmount, [dispatch]);

  return (
    <Input
      icon="fa-solid fa-magnifying-glass"
      showEraserButton
      placeholder={t('game_provider_search_label')}
      onClick={handleOnClick}
      onChange={handleSearchValue}
      onErase={resetSearchValue}
      isContainerColor
      showSpinner={isLoading}
      focusOnMount={focusOnMount}
      value={search}
    />
  );
}
