import { RankProgressProps } from './props';
import useTranslation from 'next-translate/useTranslation';
import RankLabel from '../RankLabel';
import styles from './styles.module.scss';
import Link from '../core/Link';
import RankProgressSkeleton from './Skeleton';

export default function RankProgress({
  rankStatus,
  loading,
  isRow,
}: RankProgressProps) {
  const { t } = useTranslation('common');
  const currentExp: number = Number(rankStatus?.experience);
  const nextLv: number = Number(rankStatus?.nextRank?.experience);

  if (loading) {
    return <RankProgressSkeleton isRow={isRow} />;
  }

  return (
    <div
      className={`${styles.container} ${isRow ? styles['container--row'] : ''}`}
    >
      {!isRow && (
        <div
          className={`${styles.container__row} ${styles['container__row--between']}`}
        >
          <div
            className={`${styles.container__row} ${styles['container__row--small']}`}
          >
            <span className={styles.container__label}>
              {t('rank_progress_title')}
            </span>
            <span
              className={`${styles.container__label} ${styles['container__label--secondary']}`}
            >
              {Number(rankStatus?.progress ?? 0).toFixed(0)}%
            </span>
          </div>

          <Link
            href="/vip-club"
            className={`${styles['container__label']} ${styles['container__label--link']}`}
          >
            <div
              className={`${styles.container__row} ${styles['container__row--small']}`}
            >
              {t('view_club_vip')}
              <i className="fa-solid fa-chevron-right" />
            </div>
          </Link>
        </div>
      )}
      {isRow && (
        <div className={styles.container__row__labelprogress}>
          <span>{currentExp} XP</span>
          <span>{nextLv} XP</span>
        </div>
      )}
      <div
        className={`${styles['container__progress-container']} ${isRow ? styles['container__progress-container--row'] : ''}`}
      >
        <div
          className={styles['container__progress-container__progress']}
          style={{
            width: `${rankStatus?.progress ?? 0}%`,
            backgroundColor: rankStatus?.rank?.group?.color,
          }}
        />
      </div>

      <div
        className={`${styles.container__row} ${styles['container__row--between']}`}
      >
        {!isRow && <RankLabel rank={rankStatus?.rank} loading={loading} />}

        {!isRow && rankStatus?.nextRank !== null && (
          <RankLabel rank={rankStatus?.nextRank} loading={loading} />
        )}
      </div>
    </div>
  );
}
