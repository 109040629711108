import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import { Image as CoreImage } from '@starsoft/common/components';
import MainBannerContentRenderer from './Renderer';
import { useResolvedTheme } from '@/hooks/theme/useResolvedTheme';
import { SettingLayoutComponentId } from '@starsoft/common/models';
import { ComponentTypeId } from '@starsoft/common/models';

export default function HomeMainBanner() {
  const { t } = useTranslation('common');
  const { components } = useResolvedTheme();
  const version: SettingLayoutComponentId = components.get(
    ComponentTypeId.MainBanners,
  );

  function getVersionClass(): string {
    switch (version) {
      case SettingLayoutComponentId.MainBannerV1:
        return styles['container--v1'];
      case SettingLayoutComponentId.MainBannerV2:
        return styles['container--v2'];
      default:
        return styles['container--v1'];
    }
  }

  return (
    <section className={`${styles.container} ${getVersionClass()}`}>
      <CoreImage
        bundle
        width={1200}
        height={300}
        format="rectangle"
        className={styles.container__background}
        src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/artworks/banners/home/desk/casino.webp`}
      />

      <div className={styles.container__content}>
        <div className={styles.container__column}>
          <h1 className={styles.container__title}>
            {t('welcome_sign_out_label', {
              app_provider: process.env.NEXT_PUBLIC_BET_NAME,
            })}
          </h1>
          <MainBannerContentRenderer />
          <div className={styles.container__background__holder} />
        </div>
      </div>
    </section>
  );
}
