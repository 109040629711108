import { TableGridColumn } from '@starsoft/common/src/components/TableGrid/props';
import TableUser from '@/components/core/Table/CustomCells/TableUser';
import TablePositionCell from '@/components/core/Table/CustomCells/TablePositionCell';
import { UnifiedBet } from '@/models/bet/unified';
import TableGameBetCell from '@/components/core/Table/CustomCells/TableGameBetCell';

export const luckiestWinsColumns: TableGridColumn<UnifiedBet>[] = [
  {
    field: 'amount',
    headerName: 'table_position',
    renderCell: (row, index) => <TablePositionCell position={index + 1} />,
  },
  {
    field: 'player.username',
    headerName: 'table_username',
    renderCell: (row: UnifiedBet) => <TableUser player={row?.player} />,
  },
  {
    field: 'multiplier',
    headerName: 'table_game_multiplier',
    valueFormatter: (unifiedBet: UnifiedBet) =>
      `${unifiedBet?.multiplier?.toFixed?.(2)}x`,
  },
  {
    field: 'profit',
    headerName: 'table_game_win',
    renderCell: unifiedBet => (
      <TableGameBetCell bet={unifiedBet} isProfitStyle />
    ),
  },
];
