import { useGames } from '@/api/games';
import GameCard from '@/components/core/Games/GameCard/Versions/Primary';
import { Game } from '@/models/game';
import { GameTagFilter } from '@/models/games/game-tag-filter.enum';
import { Section } from '@starsoft/common/components';

export default function GameCategoryOriginals() {
  const {
    games,
    error,
    isLoading,
    refetch,
    metadata,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useGames({
    tag: GameTagFilter.Originals,
    limit: 20,
  });

  return (
    <Section
      data={games as Game[]}
      limit={20}
      paginated
      paginationType="manual"
      error={error}
      hasMore={hasNextPage}
      isLoading={isLoading}
      isLoadingMore={isFetchingNextPage}
      loadMore={fetchNextPage}
      refetch={refetch}
      totalCount={metadata?.count}
      componenents={{
        component: game => (
          <GameCard
            game={game}
            key={`game-card-section-originals-${game?.id}`}
          />
        ),
        skeleton: index => (
          <GameCard
            isLoading
            key={`game-card-section-originals-${index}-skeleton`}
          />
        ),
      }}
    />
  );
}
