import styles from './styles.module.scss';
import { m as motion } from 'framer-motion';
import { slideImage } from './variants';
import { StoryImageProps } from './props';
import { Button } from '@starsoft/common/components';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

export default function StoryImage({ story }: StoryImageProps) {
  const { t } = useTranslation('common');
  const { push } = useRouter();

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    push(story?.link);
  }

  return (
    <div className={styles.container}>
      <div className={styles.container__wrapper}>
        <motion.img
          src={story.url}
          variants={slideImage}
          className={styles.container__image}
          initial="hidden"
          animate="visible"
          exit="exit"
        />
        <div className={styles.container__content}>
          <h3 className={styles.container__content__title}>
            {t(story?.title)}
          </h3>
          <p className={styles.container__content__description}>
            {t(story?.description)}
          </p>
        </div>
      </div>
      <Button
        className={styles.container__content__button}
        onClick={handleClick}
      >
        {t('bonus:see_details')}
      </Button>
    </div>
  );
}
