const BASE_URL: string = process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL;

// export const stories: string[] = [
//   `${BASE_URL}/stories/1.png`,
//   `${BASE_URL}/stories/2.png`,
//   `${BASE_URL}/stories/3.png`,
//   `${BASE_URL}/stories/4.png`,
//   `${BASE_URL}/stories/5.png`,
//   `${BASE_URL}/stories/6.png`,
// ];

export const stories = [
  {
    image: `${BASE_URL}/stories/1.png`,
    title: 'cashback_title',
    description: 'cashback_description',
    link: '/bonus',
  },
  // {
  //   image: `${BASE_URL}/stories/2.png`,
  //   title: 'free_spin_title',
  //   description: 'cashback_description',
  //   link: '/bonus',
  // },
  {
    image: `${BASE_URL}/stories/3.png`,
    title: 'sports_cashback_title',
    description: 'sports_cashback_description',
    link: '/sports?bt-path=%2Flive',
  },
  {
    image: `${BASE_URL}/stories/4.png`,
    title: 'rakeback_title',
    description: 'rakeback_description',
    link: '/bonus',
  },
  {
    image: `${BASE_URL}/stories/5.png`,
    title: 'vip_club_title',
    description: 'vip_club_description',
    link: '/vip-club',
  },
  {
    image: `${BASE_URL}/stories/6.png`,
    title: 'tournaments:tournaments_label',
    description: 'tournaments:home_tabs_description_tournaments',
    link: '/tournaments',
  },
];
