import styles from './styles.module.scss';
import { BiggestWinsProps } from './props';
import { WalletService } from '@starsoft/common/services';
import GameCardBiggestWinsSkeleton from './Skeleton';
import { memo } from 'react';
import Link from '@/components/core/Link';
import { GameTagFilter } from '@/models/games/game-tag-filter.enum';
import { Image } from '@starsoft/common/components';
import { ProfileStatus } from '@/enums/profileStatus';
import useTranslation from 'next-translate/useTranslation';

function GameCardBiggestWins({ bet, isLoading, isLuckiest }: BiggestWinsProps) {
  const { t } = useTranslation('common');
  const isPrivateUser = bet?.player?.status != ProfileStatus.Visible;

  if (isLoading) {
    return <GameCardBiggestWinsSkeleton />;
  }

  return (
    <Link
      href={`${
        bet?.game?.slug?.includes('original')
          ? `/games/tag/originals/${bet?.game?.slug}`
          : `/games/tag/${GameTagFilter.Slots}/${bet?.game?.slug}`
      }`}
      className={`${styles.card} ${styles['card--biggest-wins']} ${isLuckiest ? styles['card--luckiest'] : ''}`}
    >
      <Image
        src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/games/100x100${bet?.game?.image}`}
        className={styles.card__image}
        width={100}
        height={100}
        alt={`biggest-win-card-${bet?.id}`}
      />
      <div className={styles.card__content}>
        <div className={styles.card__row}>
          <div
            className={`${styles.card__content__wrapper__name} ${isPrivateUser ? styles['card__content__wrapper__name--private'] : ''}`}
          >
            {(!isPrivateUser && bet?.player?.username) || (
              <>
                <i className="fa-duotone fa-user-secret" />
                <p>{t('private_label')}</p>
              </>
            )}
          </div>

          <div
            className={`${styles['card__content__wrapper__name']} ${styles[`card__content__wrapper__name--${isLuckiest ? 'success' : 'primary'}`]}`}
          >
            {bet?.multiplier}x
          </div>
        </div>

        <div className={styles.card__content__wrapper}>
          <Image
            format="square"
            src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/coins/rounded/${bet?.coin?.image}`}
            width={17}
            alt={`coin-image-${bet?.coin?.name}`}
            height={17}
            className={styles.card__content__wrapper__photo}
          />
          <div
            className={`${styles.card__content__wrapper__name} ${styles['card__content__wrapper__name--coin']}`}
          >
            {WalletService.maskCurrency({
              amount: bet?.profit ?? 0,
              coin: bet?.coin,
            })}
          </div>
        </div>
        <div className={styles.card__description}>{bet?.game?.name}</div>
      </div>
    </Link>
  );
}

export default memo(GameCardBiggestWins);
