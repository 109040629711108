import { GameTagFilter } from '@/models/games/game-tag-filter.enum';
import GameLobby from './Lobby';
import { GameRenderer } from './props';
import GameCategoryOriginals from './Categories/Originals';
import GameCategories from './Categories';

export default function CarouselsGameRenderer({ tag }: GameRenderer) {
  switch (tag) {
    case GameTagFilter.Originals:
      return <GameCategoryOriginals />;
    case GameTagFilter.Slots:
    case GameTagFilter.Fortune:
    case GameTagFilter.LiveCasino:
    case GameTagFilter.Roulette:
      return <GameCategories tag={tag} />;
    default:
      return <GameLobby />;
  }
}
